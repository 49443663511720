export default function Fish ({ state, pos }) {
  const group = state.add.group()

  const small = state.add.sprite(pos.x, pos.y, 'sprites', 'puffer-small')
  const big = state.add.sprite(pos.x, pos.y, 'sprites', 'puffer-big')
  group.addMultiple([small, big])

  const xOffset = 10 + Math.random() * 10
  const yOffset = 10 + Math.random() * 10
  const xTime = 3000 + Math.random() * 2000
  const yTime = 3000 + Math.random() * 2000

  // Add a simple bounce tween to each character's position.
  state.add.tween(group).to(
    {y: yOffset},
    yTime,
    'Sine.easeInOut',
    true,
    0,
    -1,
    true
  )
  state.add.tween(group).to(
    {x: xOffset},
    xTime,
    'Sine.easeInOut',
    true,
    0,
    -1,
    true
  )

  // Add another rotation tween to the same character.
  // state.add.tween(group).to({angle: 360}, 2400, Phaser.Easing.Quadratic.InOut, true, 1000 + 400 * i, 0);

  showSmall()

  function showSmall () {
    small.visible = true
    big.visible = false
  }

  function showBig () {
    small.visible = false
    big.visible = true
  }

  const API = {
    showBig,
    showSmall
  }

  return API
}
