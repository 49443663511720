/* global Phaser */

import Boot from './states/boot'
import Preload from './states/preload'
import Round from './states/round'
import Game from './states/game'
import GameOver from './states/game-over'

let game

const KanaGrid = {
  init (params) {
    game = new Phaser.Game(1366, 768, Phaser.AUTO, 'game')
    game.data = params
    game.state.add('boot', Boot)
    game.state.add('preload', Preload)
    game.state.add('round', Round)
    game.state.add('game', Game)
    game.state.add('game-over', GameOver)
    game.state.start('boot')
    return game
  },

  destroy () {
    if (game){
      game.destroy()
    }
    game = null
  }
}

export default KanaGrid
