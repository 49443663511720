/* globals Phaser */
import Backs from './../entities/backs'
import Cat from './../entities/cat'
import Celebration from './../entities/celebration'
import Kana from './../entities/kana'
import Tank from './../entities/tank'

const Game = function Game () {}

const GRID = {
  x: 614,
  y: 142,
  height: 93,
  width: 93
}

const ROWS = 6
const COLS = 6
const TARGETS = 6

let state
let game

let backs
let cat
let correct
let focusText
let gridKanas
let focus
let tank
let $press

let backsGroup
let catGroup
let focusIndex
let focusTextGroup
let gridGroup
let tankGroup

// ///////////////////////////
//
// Drop a font, use the stack
//
// ///////////////////////////

Game.prototype = {
  create () {
    state = this
    game = state.game

    state.add.sprite(0, 0, 'game-back')
    $press = new Phaser.Signal()
    $press.add(handlePress)

    catGroup = state.add.group()
    focusTextGroup = state.add.group()
    tankGroup = state.add.group()
    backsGroup = state.add.group()
    gridGroup = state.add.group()

    backs = Backs({ state, parent: backsGroup })
    tank = Tank({ state, parent: tankGroup })
    cat = Cat({ state, parent: catGroup, x: 118, y: 154 })
    focusText = addFocusText({ focus: '', x: 410, y: 510, r: -2 })
    newRound()
  }
}

function newRound () {
  correct = 0

  focusIndex = game.data['romaji'].indexOf(game.data.focus[game.data.round])
  focus = game.data[game.data.guideKana][focusIndex]
  focusText.text = focus

  const clickables = makeClickables({ focusIndex })
  gridKanas = addKanas({ parent: gridGroup, clickables, $press })
}

function addFocusText ({ focus, x, y, r }) {
  let stack = game.data.kanaStack
  if (game.data.focusKana === 'romaji') {
    stack = game.data.romajiStack
  }
  const style = {
    font: `bold 136px ${stack}`,
    fill: '#33303b'
  }
  const text = state.add.text(x, y, focus, style)
  text.anchor.set(0.5)
  text.angle = r
  focusTextGroup.add(text)
  return text
}

function shuffle (arr) {
  return arr.sort(() => (Math.random() - 0.5))
}

function makeClickables ({ focusIndex }) {
  const allTargets = []
  const focusKanas = game.data[game.data.focusKana]

  const max = ROWS * COLS
  for (var i = 0; i < TARGETS; i++) {
    allTargets.push(focusIndex)
  }

  const allDistractors = []
  for (i = 0; i < focusKanas.length; i++) {
    if (i !== focusIndex) {
      allDistractors.push(i)
    }
  }

  const distractors = shuffle(allDistractors).slice(0, max - TARGETS)

  return shuffle([...allTargets, ...distractors])
}

function addKanas ({ parent, clickables, $press }) {
  const kanas = []

  let i = 0
  for (let row = 0; row < 6; row++) {
    for (let col = 0; col < 6; col++) {
      const index = clickables[i]
      const x = GRID.x + col * GRID.width
      const y = GRID.y + row * GRID.height

      kanas.push(Kana({
        game,
        state,
        parent,
        index,
        $press,
        pos: {x, y},
        grid: { row, col }
      }))
      i++
    }
  }
  return kanas
}

function disableKanas () {
  gridKanas.forEach(kana => kana.disable())
}

function handlePress (e) {
  e.el.disable()
  if (e.el.getIndex() === focusIndex) {
    handleCorrect(e.el)
  } else {
    handleIncorrect()
  }
}

function handleCorrect (el) {
  correct++
  state.sound.play(`correct_${correct}`, 0.4)
  backs.show({ row: el.grid.row, col: el.grid.col })
  cat.showCorrect()
  tank.pop()
  state.sound.play(`cork`, 0.2)
  if (correct === 6) {
    handleRoundComplete()
  }
}

function handleIncorrect () {
  state.sound.play(`incorrect`, 0.4)
  cat.showIncorrect()
}

function handleRoundComplete () {
  state.sound.play('koto', 1)
  disableKanas()
  setTimeout(endRound, 3500)
  Celebration({ game, state })
}

function cleanup () {
  gridKanas.forEach((kana) => {
    kana.destroy()
    kana = null
  })
  backs.reset()
  tank.reset()
}

function endRound () {
  cleanup()
  game.data.round++
  if (game.data.round === game.data.focus.length) {
    finn()
  } else {
    state.state.start('round')
  }
}

function finn () {
  state.state.start('game-over')
}

export default Game
