export default function injectWebFont (family, url) {
  function addStyleString (str) {
    const node = document.createElement('style')
    node.innerHTML = str
    document.head.appendChild(node)
  }

  const style = `@font-face {
    font-family: '${family}';
    src: url('${url}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }`

  addStyleString(style)
}
