const Round = function Round () {}

let state
let game

Round.prototype = {
  init: function preloaderInit () {
    state = this
  },

  create: function () {
    state = this
    game = state.game
    state.add.sprite(0, 0, 'round-back')

    const index = game.data['romaji'].indexOf(game.data.focus[game.data.round])
    const guideStr = game.data[game.data.guideKana][index]
    const kanaStr = game.data[game.data.focusKana][index]
    makeText({ state, str: guideStr, x: 320, y: 300, size: 148, angle: -1.5 })
    makeText({ state, str: kanaStr, x: 1056, y: 310, size: 158, angle: 3 })

    const str = `Round ${game.data.round + 1} of ${game.data.focus.length}`
    makeText({ state, str, x: 678, y: 564, size: 62 })

    const play = state.add.sprite(490, 610, 'sprites', 'btn-play-now')

    play.inputEnabled = true
    play.input.useHandCursor = true
    play.events.onInputDown.add(() => state.state.start('game'))
  }
}

function makeText ({ state, str, x, y, size, angle = 0, fill = '#000000' }) {
  let stack = game.data.romajiStack
  const style = { font: `bold ${size}px ${stack}`, fill }
  const text = state.add.text(x, y, str, style)
  text.anchor.set(0.5)
  text.angle = angle
  return text
}

export default Round
