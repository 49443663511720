const GameOver = function GameOver () {}

let state
let game

let playAgain
let playNew

GameOver.prototype = {
  create: function () {
    state = this
    game = state.game
    state.add.sprite(0, 0, 'game-over')

    playAgain = state.add.sprite(760, 240, 'sprites', 'btn-play-again')
    playNew = state.add.sprite(660, 390, 'sprites', 'btn-play-different')

    playAgain.inputEnabled = true
    playAgain.input.useHandCursor = true
    playAgain.events.onInputDown.add(handlePlayAgain)

    playNew.inputEnabled = true
    playNew.input.useHandCursor = true
    playNew.events.onInputDown.add(handleNewGame)
  }
}

function handlePlayAgain () {
  playAgain.events.onInputDown.removeAll()
  playNew.events.onInputDown.removeAll()
  game.data.round = 0
  state.state.start('game')
}

function handleNewGame () {
  playAgain.events.onInputDown.removeAll()
  playNew.events.onInputDown.removeAll()
  game.data.round = 0
  window.location.href = 'http://drlingua.com/japanese/games/kana-grid'
}

export default GameOver
