export default function Celebration ({ game, state }) {
  addFish()

  function addFish () {
    let emitter = game.add.emitter(-50, 300, 400)
    emitter.makeParticles('soy-bubble')
    // emitter.setAlpha(0.1, 1, 3000)
    emitter.setScale(0.1, 1, 0.1, 1, 6000, 'Quint.easeOut')
    emitter.gravity = 0
    emitter.gravity = 600
    emitter.emitX = -300
    emitter.start(false, 1500, 20)

    const tweenA = game.add.tween(emitter).to({ emitX: 1450 }, 2000, 'Linear', true)
    const tweenB = game.add.tween(emitter).to({ emitY: 80 }, 1000, 'Sine.easeIn', true, 0, 0, true)
    tweenA.chain(tweenB)

    const fish1 = state.add.sprite(-250, 300, 'sprites', 'celebration-fish')
    fish1.anchor.setTo(0.5, 0)
    fish1.scale.set(-1, 1)
    fish1.angle = -110
    const tweenC = game.add.tween(fish1).to({ x: 1500, angle: -70 }, 2000, 'Linear', true)
    const tweenD = game.add.tween(fish1).to({ y: 80 }, 1000, 'Sine.easeInOut', true, 0, 0, true)
    tweenC.chain(tweenD)
  }
}
