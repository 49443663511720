export default function Backs ({ state, parent }) {
  const group = state.add.group()
  parent.add(group)
  const backs = []
  for (let row = 0; row < 6; row++) {
    backs[row] = []
    for (let col = 0; col < 6; col++) {
      const sprite = state.add.sprite(0, 0, 'sprites', `grid_${row}_${col}`)
      backs[row][col] = sprite
      group.add(sprite)
      sprite.visible = false
    }
  }

  function reset () {
    for (let row = 0; row < 6; row++) {
      for (let col = 0; col < 6; col++) {
        backs[row][col].visible = false
      }
    }
  }

  function show ({ col, row }) {
    backs[col][row].visible = true
  }

  const API = {
    reset,
    show
  }

  return API
}
