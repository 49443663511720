/* globals Phaser */

import React from 'react'
import FontFaceObserver from 'fontfaceobserver'
import Link from 'gatsby-link'
import Layout from '../../../components/Layouts/Default'

import Game from '../../../components/Game'
import HorizontalKanaTable from '../../../components/HorizontalKanaTable'
import KanaGridGame from '../../../games/kanaGrid/main'
import Seo from '../../../components/Seo'

const NATIVE_WIDTH = 1366
const NATIVE_HEIGHT = 768
const TOP_NAV_HEIGHT = 40

export default class KanaGrid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      game: null,
      romaji: [
        'wa',
        'ra',
        'ya',
        'ma',
        'ha',
        'na',
        'ta',
        'sa',
        'ka',
        'a',
        'ri',
        'mi',
        'hi',
        'ni',
        'chi',
        'shi',
        'ki',
        'i',
        'wo',
        'ru',
        'yu',
        'mu',
        'fu',
        'nu',
        'tsu',
        'su',
        'ku',
        'u',
        're',
        'me',
        'he',
        'ne',
        'te',
        'se',
        'ke',
        'e',
        'n',
        'ro',
        'yo',
        'mo',
        'ho',
        'no',
        'to',
        'so',
        'ko',
        'o',
      ],
      hiragana: [
        'わ',
        'ら',
        'や',
        'ま',
        'は',
        'な',
        'た',
        'さ',
        'か',
        'あ',
        'り',
        'み',
        'ひ',
        'に',
        'ち',
        'し',
        'き',
        'い',
        'を',
        'る',
        'ゆ',
        'む',
        'ふ',
        'ぬ',
        'つ',
        'す',
        'く',
        'う',
        'れ',
        'め',
        'へ',
        'ね',
        'て',
        'せ',
        'け',
        'え',
        'ん',
        'ろ',
        'よ',
        'も',
        'ほ',
        'の',
        'と',
        'そ',
        'こ',
        'お',
      ],
      katakana: [
        'ワ',
        'ラ',
        'ヤ',
        'マ',
        'ハ',
        'ナ',
        'タ',
        'サ',
        'カ',
        'ア',
        'リ',
        'ミ',
        'ヒ',
        'ニ',
        'チ',
        'シ',
        'キ',
        'イ',
        'ヲ',
        'ル',
        'ユ',
        'ム',
        'フ',
        'ヌ',
        'ツ',
        'ス',
        'ク',
        'ウ',
        'レ',
        'メ',
        'ヘ',
        'ネ',
        'テ',
        'セ',
        'ケ',
        'エ',
        'ン',
        'ロ',
        'ヨ',
        'モ',
        'ホ',
        'ノ',
        'ト',
        'ソ',
        'コ',
        'オ',
      ],
      focii: {
        a: ['a', 'i', 'u', 'e', 'o'],
        ka: ['ka', 'ki', 'ku', 'ke', 'ko'],
        sa: ['sa', 'shi', 'su', 'se', 'so'],
        ta: ['ta', 'chi', 'tsu', 'te', 'to'],
        na: ['na', 'ni', 'nu', 'ne', 'no'],
        ha: ['ha', 'hi', 'fu', 'he', 'ho'],
        ma: ['ma', 'mi', 'mu', 'me', 'mo'],
        ya: ['ya', 'yu', 'yo'],
        ra: ['ra', 'ri', 'ru', 're', 'ro'],
        wa: ['wa', 'wo', 'n'],
      },
      focus: ['a', 'i', 'u', 'e', 'o'],
      guideKana: 'romaji',
      focusKana: 'hiragana',
      hasValidParams: false,
    }

    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.render = this.render.bind(this)

    this._handleResize = this.handleResize.bind(this)
  }

  handleScriptError() {
    this.forceUpdate()
  }

  handleScriptLoad() {
    function CustomLoader(game) {
      Phaser.Loader.call(this, game)
    }

    CustomLoader.prototype = Object.create(Phaser.Loader.prototype)
    CustomLoader.prototype.constructor = CustomLoader

    CustomLoader.prototype.webfont = function webFont(key, fontName, overwrite) {
      if (typeof overwrite === 'undefined') {
        overwrite = false
      }

      this.addToFileList('webfont', key, fontName)
      return this
    }

    CustomLoader.prototype.loadFile = function loadFile(file) {
      Phaser.Loader.prototype.loadFile.call(this, file)

      if (file.type === 'webfont') {
        const context = this
        const font = new FontFaceObserver(file.url)
        font.load(null, 10000).then(
          function success() {
            context.asyncComplete(file)
          },
          function error() {
            context.asyncComplete(file, `Error loading font ${file.url}`)
          }
        )
      }
    }

    if (this && this.state) {
      const kanaGrid = Object.create(KanaGridGame)
      this.setState({
        game: kanaGrid,
      })
      kanaGrid.init({
        romaji: this.state.romaji,
        hiragana: this.state.hiragana,
        katakana: this.state.katakana,
        focus: this.state.focus,
        focusKana: this.state.focusKana,
        guideKana: this.state.guideKana,
        CustomLoader,
      })
    } else {
      console.log('Kana grid state no longer exists, move on without creating game')
      // debugger
    }
  }

  handleResize() {
    const w = window.innerWidth
    const h = window.innerHeight - TOP_NAV_HEIGHT
    const scale = Math.min(w / NATIVE_WIDTH, h / NATIVE_HEIGHT)
    const width = Math.round(scale * NATIVE_WIDTH)
    const height = Math.round(scale * NATIVE_HEIGHT)
    this.setState({
      width: width,
      height: height,
    })
  }

  validateParams({ f, gk, fk }) {
    // 'f': focus - an array column of kanas from kana chart
    // 'gk': guideKana - the displayed single kana showing current focus
    // 'fk': focusKana - the kana form we're searching

    let isValid = true
    if (!(f && gk && fk)) {
      isValid = false
    }
    if (!['a', 'ka', 'sa', 'ta', 'na', 'ha', 'ma', 'ya', 'ra', 'wa'].includes(f)) {
      isValid = false
    }
    if (!(gk === 'r' || gk === 'h' || gk === 'k')) {
      isValid = false
    }
    if (!(fk === 'r' || fk === 'h' || fk === 'k')) {
      isValid = false
    }
    return isValid
  }

  parseSearchString(str) {
    function expand(str) {
      switch (str) {
        case 'h':
          return 'hiragana'
        case 'k':
          return 'katakana'
        default:
          return 'romaji'
      }
    }
    const params = {}
    str
      .substring(1)
      .split('&')
      .forEach((substr) => {
        const [k, v] = substr.split('=')
        params[k] = v
      })
    if (!this.validateParams(params)) {
      this.setState({
        hasValidParams: false,
      })
      console.warn('Inavalid game parameters', params)
    } else {
      const { f, gk, fk } = params
      const guideKana = expand(gk)
      const focusKana = expand(fk)
      this.setState({
        hasValidParams: true,
        focus: this.state.focii[f],
        guideKana,
        focusKana,
      })
    }
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.parseSearchString(this.props.location.search)
    }
    const script = document.createElement('script')

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/phaser/2.6.2/phaser.min.js'
    script.async = true
    script.addEventListener('load', () => {
      this.handleScriptLoad()
    })
    script.addEventListener('error', (e) => {
      this.handleScriptError()
    })

    document.body.appendChild(script)

    this.handleResize()
    this.windowListener = window.addEventListener('resize', this._handleResize)

    return () => {
      document.body.removeChild(script)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
    if (this && this.state && this.state.game) {
      this.state.game.destroy()
    } else {
      console.log('Unsuccessfully tried to destroy Kana Grid', this.state)
    }
  }

  render() {
    return (
      <Layout>
        <div>
          <Seo meta="kana-grid" />
          <div>
            <div className="kana-grid">
              <Game width={this.state.width} height={this.state.height} />
            </div>
          </div>
          <div className="basic kanagrid">
            <h1 className="h3 center">{'Hiragana and Katakana game - Kana Grid'}</h1>
            <p>
              {'The '}
              <a href="#studyguide">{`resource guide`}</a>
              {` shows how to control game kanas for teachers / parents / self studyers.`}
            </p>
            <h3>{'Hiragana and Katanana kana game'}</h3>
            <p>
              {`Kana Grid is an early Japanese learning game designed to familiarise players with the Hiragana and Katakana Japanese scripts. I've primarily make it for younger kids – it's designed to run on a tablet or a desktop computer. While it `}
              <em>{`technically can`}</em>
              {` run on a phone, it probably shouldn't – unless you have a tablet, good eyes, and small fingers, in which case you should be fine :)`}
            </p>
            <p>{`This game is aimed squarely at the first stage of learning to differentiate kanas. A grid of Hiragana or Katakana characters is shown on screen, and the usre has to pick the six elments of the 'focus' kana.`}</p>
            <h4>{`Play Kana Grid`}</h4>
            <p>{`The user is shown a screen listing games 'focus' in a familiar script - starting with a / あ / ア, and a grid of hiragana or katakana kanas, six matching the 'focus'. Once the player has selected the six matching kana, the round ends, and the game moves onto the next kana. Each game has either 5 or 3 rounds consisting of kanas from a row of the hiragana/katakana chart.`}</p>
            <h3>{`Questions, corrections, comments?`}</h3>
            <p>
              {`Please get in touch. I `}
              <em>{`really`}</em>
              {` appreciate corrections and input. You can `}
              <Link to="/contact/">{`contact me here`}</Link>
              {`.`}
            </p>
            <a name="studyguide" />
            <h2>{`Teachers, parents, self motivated, resource guide`}</h2>
            <p>{`You can create a link to a game targetting specific kanas and focus kanas to use. Below are 4 screenshots showing various kana combinations, and below them are links to specific 'focus' kana sets for different games.`}</p>
            <p>{`Clicking 'play now' opens the game at that game type. Clicking 'copy link' copies a link to that game type into your clipboard to paste wherever you like.`}</p>
            <hr />
            <h3>{`Play with Hiragana Kanas, Romaji guide text`}</h3>
            <img
              src="/assets/images/kana-grid-hiragana-game-romaji.jpg"
              alt="Kana Grid focusing on hiragana kanas, with romaji guide."
            />
            <div className="table">
              <h4>{`Game links`}</h4>
              <h5>{`Hiragana Kanas, Romaji guide text`}</h5>
              <HorizontalKanaTable guideKana="r" focusKana="h" />
            </div>
            <hr />
            <h3>{`Play with Hiragana Kanas, Katakana guide text`}</h3>
            <img
              src="/assets/images/kana-grid-hiragana-game-katakana.jpg"
              alt="Kana Grid focusing on hiragana kanas, with romaji guide."
            />
            <div className="table">
              <h4>{`Game links`}</h4>
              <h5>{`Hiragana Kanas, Katakana guide text`}</h5>
              <HorizontalKanaTable guideKana="k" focusKana="h" />
            </div>
            <hr />
            <h3>{`Play with Katakana Kanas, Romaji guide text`}</h3>
            <img
              src="/assets/images/kana-grid-katakana-game-romaji.jpg"
              alt="Kana Grid focusing on katakana kanas, with romaji guide."
            />
            <div className="table">
              <h4>{`Game links`}</h4>
              <h5>{`Katakana Kanas, Romaji guide text`}</h5>
              <HorizontalKanaTable guideKana="r" focusKana="k" />
              <hr />
            </div>
            <hr />
            <h3>{`Play with Katakana Kanas, Hiragana guide text`}</h3>
            <img
              src="/assets/images/kana-grid-katakana-game-hiragana.jpg"
              alt="Kana Grid focusing on katakana kanas, with hiragana guide."
            />
            <div className="table">
              <h4>{`Game links`}</h4>
              <h5>{`Katakana Kanas, Hiragana guide text`}</h5>
              <HorizontalKanaTable guideKana="h" focusKana="k" />
              <hr />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
