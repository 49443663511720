export default function Cat ({ state, parent, x, y }) {
  const group = state.add.group()
  parent.add(group)

  const main = state.add.sprite(x, y, 'sprites', 'cat-face-main')
  const correct = state.add.sprite(x, y, 'sprites', 'cat-face-correct')
  const incorrect = state.add.sprite(x, y, 'sprites', 'cat-face-incorrect')

  showMain()

  function hideAll () {
    main.visible = false
    correct.visible = false
    incorrect.visible = false
  }

  function showMain () {
    hideAll()
    main.visible = true
  }

  function showCorrect () {
    hideAll()
    correct.visible = true
  }

  function showIncorrect () {
    hideAll()
    incorrect.visible = true
  }

  const API = {
    showMain,
    showCorrect,
    showIncorrect
  }

  return API
}
