import injectWebFont from './../../helpers/injectWebfont'

const Preload = function preload () {}

let state

Preload.prototype = {
  init: function preloaderInit () {
    state = this
  },

  preload: function () {
    state.add.sprite(620, 254, 'puffer-big')
    const sprite = state.add.sprite(300, 395, 'load-bar')

    makeText({ state, str: `This game is made for tablets and computers.`, x: 683, y: 80, size: 48 })
    makeText({ state, str: `If you're on a phone.... It's not going to be great...`, x: 683, y: 160, size: 48 })
    makeText({ state, str: `Seriously, if you you're on a phone.... You should bail now.`, x: 683, y: 560, size: 48 })

    state.load.audio('correct_1', '/assets/games/common/audio/correct_xylophone_1.mp3')
    state.load.audio('correct_2', '/assets/games/common/audio/correct_xylophone_2.mp3')
    state.load.audio('correct_3', '/assets/games/common/audio/correct_xylophone_3.mp3')
    state.load.audio('correct_4', '/assets/games/common/audio/correct_xylophone_4.mp3')
    state.load.audio('correct_5', '/assets/games/common/audio/correct_xylophone_5.mp3')
    state.load.audio('correct_6', '/assets/games/common/audio/correct_xylophone_6.mp3')
    state.load.audio('incorrect', '/assets/games/common/audio/slide_incorrect.mp3')
    state.load.audio('cork', '/assets/games/common/audio/cork.mp3')
    state.load.audio('koto', '/assets/games/common/audio/koto.mp3')

    state.load.image('game-back', '/assets/games/kana-grid/images/game-back.jpg')
    state.load.image('round-back', '/assets/games/kana-grid/images/round-back.jpg')
    state.load.image('soy-bubble', '/assets/games/common/images/soy-bubble.png')
    state.load.image('game-over', '/assets/games/kana-grid/images/game-over.jpg')

    const tag = 'sprites'
    const imgUrl = '/assets/games/kana-grid/images/kana-grid.png'
    const jsonUrl = '/assets/games/kana-grid/images/kana-grid.json'
    state.load.atlasJSONHash(tag, imgUrl, jsonUrl)

    let fonts = [
      { family: 'irohamaru mikami', url: '/assets/games/common/fonts/irohamaru-mikami-Medium.ttf' },
      { family: 'GenEi LateMin', url: '/assets/games/common/fonts/GenEiLateMinT-M.ttf' }
    ]
    loadFonts({ state, fonts })

    state.load.setPreloadSprite(sprite)
  },

  create: function () {
    state.state.start('round')
  }
}

function makeText ({ state, str, x, y, size, fill = '#000000' }) {
  let stack = state.game.data.romajiStack
  const style = { font: `bold ${size}px ${stack}`, fill }
  const text = state.add.text(x, y, str, style)
  text.anchor.set(0.5)
  return text
}

function loadFonts ({ state, fonts }) {
  fonts.forEach(function eachFont (font) {
    try {
      const url = font.url
      const family = font.family
      injectWebFont(family, url)
      state.load.webfont(url, family)
    } catch (e) {
      console.error('Could not load font', font, e)
    }
  })
}

export default Preload
