import React from 'react'

import Clipper from './Clipper'

function HorizontalKanaTable (params) {
  const kanas = {
    'h': [
      'あ', 'い', 'う', 'え', 'お',
      'か', 'き', 'く', 'け', 'こ',
      'さ', 'し', 'す', 'せ', 'そ',
      'た', 'ち', 'つ', 'て', 'と',
      'な', 'に', 'ぬ', 'ね', 'の',
      'は', 'ひ', 'ふ', 'へ', 'ほ',
      'ま', 'み', 'む', 'め', 'も',
      'や', '', 'ゆ', '', 'よ',
      'ら', 'り', 'る', 'れ', 'ろ',
      'わ', '', '', '', 'を', 'ん'
    ],
    'k': [
      'ア', 'イ', 'ウ', 'エ', 'オ',
      'カ', 'キ', 'ク', 'ケ', 'コ',
      'サ', 'シ', 'ス', 'セ', 'ソ',
      'タ', 'チ', 'ツ', 'テ', 'ト',
      'ナ', 'ニ', 'ヌ', 'ネ', 'ノ',
      'ハ', 'ヒ', 'フ', 'ヘ', 'ホ',
      'マ', 'ミ', 'ム', 'メ', 'モ',
      'ヤ', '', 'ユ', '', 'ヨ',
      'ラ', 'リ', 'ル', 'レ', 'ロ',
      'ワ', '', '', '', 'ヲ', 'ン'
    ]
  }

  const { guideKana, focusKana } = params

  const url = (focus) => `/japanese/games/kana-grid?f=${focus}&gk=${guideKana}&fk=${focusKana}`

  return (
    <table>
      <tbody>
        <tr className='even'>
          <td>{kanas[focusKana][0]}</td>
          <td>{kanas[focusKana][1]}</td>
          <td>{kanas[focusKana][2]}</td>
          <td>{kanas[focusKana][3]}</td>
          <td>{kanas[focusKana][4]}</td>
          <td className='last'><Clipper url={url('a')} /></td>
        </tr>
        <tr className='odd'>
          <td>{kanas[focusKana][5]}</td>
          <td>{kanas[focusKana][6]}</td>
          <td>{kanas[focusKana][7]}</td>
          <td>{kanas[focusKana][8]}</td>
          <td>{kanas[focusKana][9]}</td>
          <td className='last'><Clipper url={url('ka')} /></td>
        </tr>
        <tr className='even'>
          <td>{kanas[focusKana][10]}</td>
          <td>{kanas[focusKana][11]}</td>
          <td>{kanas[focusKana][12]}</td>
          <td>{kanas[focusKana][13]}</td>
          <td>{kanas[focusKana][14]}</td>
          <td className='last'><Clipper url={url('sa')} /></td>
        </tr>
        <tr className='odd'>
          <td>{kanas[focusKana][15]}</td>
          <td>{kanas[focusKana][16]}</td>
          <td>{kanas[focusKana][17]}</td>
          <td>{kanas[focusKana][18]}</td>
          <td>{kanas[focusKana][19]}</td>
          <td className='last'><Clipper url={url('ta')} /></td>
        </tr>
        <tr className='even'>
          <td>{kanas[focusKana][20]}</td>
          <td>{kanas[focusKana][21]}</td>
          <td>{kanas[focusKana][22]}</td>
          <td>{kanas[focusKana][23]}</td>
          <td>{kanas[focusKana][24]}</td>
          <td className='last'><Clipper url={url('na')} /></td>
        </tr>
        <tr className='odd'>
          <td>{kanas[focusKana][25]}</td>
          <td>{kanas[focusKana][26]}</td>
          <td>{kanas[focusKana][27]}</td>
          <td>{kanas[focusKana][28]}</td>
          <td>{kanas[focusKana][29]}</td>
          <td className='last'><Clipper url={url('ha')} /></td>
        </tr>
        <tr className='even'>
          <td>{kanas[focusKana][30]}</td>
          <td>{kanas[focusKana][31]}</td>
          <td>{kanas[focusKana][32]}</td>
          <td>{kanas[focusKana][33]}</td>
          <td>{kanas[focusKana][34]}</td>
          <td className='last'><Clipper url={url('ma')} /></td>
        </tr>
        <tr className='odd'>
          <td>{kanas[focusKana][35]}</td>
          <td>{kanas[focusKana][36]}</td>
          <td>{kanas[focusKana][37]}</td>
          <td>{kanas[focusKana][38]}</td>
          <td>{kanas[focusKana][39]}</td>
          <td className='last'><Clipper url={url('ya')} /></td>
        </tr>
        <tr className='even'>
          <td>{kanas[focusKana][40]}</td>
          <td>{kanas[focusKana][41]}</td>
          <td>{kanas[focusKana][42]}</td>
          <td>{kanas[focusKana][43]}</td>
          <td>{kanas[focusKana][44]}</td>
          <td className='last'><Clipper url={url('ra')} /></td>
        </tr>
        <tr className='odd'>
          <td>{kanas[focusKana][45]}</td>
          <td>{` `}</td>
          <td>{` `}</td>
          <td>{kanas[focusKana][49]}</td>
          <td>{kanas[focusKana][50]}</td>
          <td className='last'><Clipper url={url('wa')} /></td>
        </tr>
      </tbody>
    </table>
  )
}

export default HorizontalKanaTable
