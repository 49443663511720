/* global Phaser */

export default function Kana ({ game, state, parent, index, $press, pos, grid }) {
  const group = state.add.group()
  parent.add(group)

  const kanas = []
  const fonts = [
    'irohamaru mikami',
    'GenEi LateMin',
    game.data.kanaStack
  ]

  const romaji = makeText({ state, str: game.data['romaji'][index] })
  kanas['romaji'] = romaji
  kanas['hiragana'] = makeText({ state, str: game.data['hiragana'][index] })
  kanas['katakana'] = makeText({ state, str: game.data['katakana'][index] })

  group.add(kanas['romaji'])
  group.add(kanas['hiragana'])
  group.add(kanas['katakana'])
  group.position.setTo(pos.x, pos.y)

  kanas['romaji'].alpha = 0.01
  kanas['hiragana'].visible = false
  kanas['katakana'].visible = false
  kanas[game.data.focusKana].visible = true

  romaji.hitArea = new Phaser.Rectangle(-30, -30, 60, 60)
  romaji.inputEnabled = true
  romaji.input.useHandCursor = true
  romaji.events.onInputDown.add(onPress)

  function makeText ({ state, str }) {
    let stack = fonts[Math.floor(Math.random() * fonts.length)]
    let size = game.data.kanaFontSize
    if (game.data.focusKana === 'romaji') {
      stack = game.data.romajiStack
      size = game.data.romajiFontSize
    }
    const style = {
      font: `normal ${size}px ${stack}`,
      fill: '#000000'
    }
    const text = state.add.text(0, 0, str, style)
    text.anchor.set(0.5)
    return text
  }

  function getIndex () {
    return index
  }

  function onPress (e) {
    $press.dispatch({ text: e.text, el: API })
  }

  function disable () {
    romaji.input.useHandCursor = false
    romaji.inputEnabled = false
  }

  function destroy () {
    romaji.events.onInputDown.removeAll()
    parent.remove(group)
    romaji.destroy()
    kanas['romaji'].destroy()
    kanas['katakana'].destroy()
    kanas['hiragana'].destroy()
    group.destroy()
  }

  const API = {
    destroy,
    disable,
    getIndex,
    group,
    grid
  }

  return API
}
