/* global Phaser */

const Boot = function boot () {}

let state
let game

Boot.prototype = {
  init: function bootInit () {
    state = this
    game = state.game

    game.input.maxPointers = 1
    game.scale.scaleMode = Phaser.ScaleManager.SHOW_ALL

    game.scale.pageAlignHorizontally = true
    game.scale.updateLayout(true)

    game.time.desiredFps = 60
    game.forceSingleUpdate = true
    game.stage.disableVisibilityChange = true

    game.stage.backgroundColor = '#FFFFFF'

    game.load = state.load = new game.data.CustomLoader(game)

    game.data.kanaStack = `'Hiragino Kaku Gothic Pro', Meiryo, sans-serif`
    game.data.romajiStack = `'Helvetica Neue', Helvetica, Arial, sans-serif`
    game.data.kanaFontSize = 58
    game.data.romajiFontSize = 48
    game.data.round = 0
  },

  preload: function bootPreload () {
    state.load.image('load-bar', '/assets/games/common/images/load-bar.png')
    state.load.image('puffer-big', '/assets/games/common/images/puffer-big.png')
  },

  create: function bootCreate () {
    game.state.start('preload')
  }
}

export default Boot
