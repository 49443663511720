import Fish from './fish'

export default function Tank ({ state, parent }) {
  const group = state.add.group()
  parent.add(group)

  const fish = [
    Fish({ state, pos: {x: 1150, y: 240} }),
    Fish({ state, pos: {x: 1210, y: 300} }),
    Fish({ state, pos: {x: 1190, y: 360} }),
    Fish({ state, pos: {x: 1160, y: 450} }),
    Fish({ state, pos: {x: 1200, y: 490} }),
    Fish({ state, pos: {x: 1150, y: 550} })
  ]
  let puffers = shuffle([0, 1, 2, 3, 4, 5])
  state.add.sprite(1140, 219, 'sprites', 'fish-tank-front')

  function shuffle (arr) {
    return arr.sort(() => (Math.random() - 0.5))
  }

  function pop () {
    fish[puffers.pop()].showBig()
  }

  function reset () {
    fish.forEach(f => f.showSmall())
    puffers = shuffle([0, 1, 2, 3, 4, 5])
  }

  const API = {
    pop,
    reset
  }

  return API
}
