import React from 'react'

function Game (params) {
  const width = `${params.width}px`
  const height = `${params.height}px`
  const style = { width, height }

  return (
    <div id='game' style={style} />
  )
}

export default Game
