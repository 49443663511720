import React from 'react'

function Clipper (params) {
  function copyToClipboard () {
    var textField = document.createElement('textarea')
    textField.innerText = `https://drlingua.com${params.url}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  function top () {
    window.scroll(0, 0)
  }

  const icon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='22px'
      height='22px'
      viewBox='0 0 512 512'
    >
      <g>
        <path d='M70.309,419.059c31.188,31.188,81.938,31.219,113.141,0l181.016-181c9.094-9,14.125-21.078,14.156-33.938
          c0.078-25.703-22.406-48.172-48.109-48.109c-12.859,0.031-24.922,5.063-33.984,14.219L138.184,328.559
          c-6.25,6.266-6.25,16.375,0,22.625s16.375,6.25,22.625,0l158.391-158.391c3.078-3.094,7.094-4.766,11.391-4.781
          c8.563,0,16.031,7.469,16.031,16.031c-0.016,4.297-1.688,8.313-4.734,11.328L160.809,396.434c-18.703,18.75-49.156,18.75-67.875,0
          c-18.719-18.688-18.719-49.156,0-67.875l203.641-203.641c31.188-31.188,81.953-31.188,113.141,0s31.188,81.953,0,113.141
          L251.324,396.434c-6.25,6.25-6.25,16.375,0,22.625s16.375,6.25,22.625,0L432.34,260.684c43.672-43.672,43.672-114.734,0-158.391
          c-43.656-43.672-114.719-43.672-158.391,0L70.309,305.934C39.105,337.137,39.105,387.871,70.309,419.059z' />
      </g>
    </svg>
  )

  return (
    <div>
      <a href={params.url} onClick={top}>{`play now`} </a>{' | '}
      <div className='clipper' onClick={copyToClipboard}>
        {'copy link '}
        {icon}
      </div>
    </div>
  )
}

export default Clipper
